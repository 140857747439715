import React from "react";

import profile from "../../assets/profile2.png";
import Container from "../../components/ui/Container";
import FlexRowReverse from "../../components/ui/FlexRowReverse";
import auth from "../../services/authServices";
import pattern from "../../assets/didi-imagery/pattern.svg";
import Button from "../../components/ui/Button";
import { SectionHeader } from "../../components/ui/SectionHeader";
import { Link } from "react-router-dom";
import AboutBoyImg from "../../assets/didi-imagery/AboutImg.png";
import aboutCardPattern from "../../assets/didi-imagery/aboutCardPattern.png";

const AboutSection = () => {
    const token = auth.getToken();

    // const {scrolling}  = useContext
    return (
        <section className=" min-h-[70vh] md:h-fit relative flex md:flex-row flex-col mx-0 md:mx-[3rem] lg:mx-[6rem] overflow-hidden flex-center-both py-10 smooth">
            <div className="flex relative z-10 flex-col space-y-10 p-10 basis-full md:basis-1/2">
                <SectionHeader
                    title="ليه عملنالك Pen-Didi"
                    className="font-rubik font-w-bold text-3xl md:text-4xl clr-text-primary smooth "
                    position="right-0 -top-0 sm:-top-10 -z-10 md:left-0"
                />
                <p className="font-rubik clr-text-primary smooth  text-center md:text-right text-2xl">
                    عشان حابين نوفر وقتك دي دي جابتلك كورسات
                    <br />
                    وخبراء عشان نضمنلك اعلي جودة تعليمية
                </p>

                {!token && (
                    <div className="btn-holder flex justify-center md:justify-start">
                        <Link
                            className="btn btn-3 hover-border-1 before:border-l-[1px] before:border-t-[1px] after:border-r-[1px] after:border-b-[1px] before:border-l-didiBlue before:border-t-didiBlue after:border-r-didiBlue after:border-b-didiBlue "
                            to={"/register"}
                        >
                            <div className="bg-didiBlue smooth px-7 py-4 flex-center-both gap-4">
                                <span className="text-link clr-white text-lg">أشترك الأن</span>
                            </div>
                        </Link>
                    </div>
                )}
            </div>
            {/* <div className=" bottom-0 left-0 absolute blur-sm opacity-50 md:opacity-100 md:blur-none">
                 <img
                    src={AboutBoyImg}
                    alt="aboutBoyDidiImg"
                    className="w-full md:max-w-[600px] lg:max-w-[800px]"
                /> 
                
            </div> */}

            <div className="flex relative z-10 flex-col items-center md:items-end w-full item-center space-y-10 p-10 basis-full md:basis-1/2">
                <div className="relative w-fit flex flex-col space-y-20">
                    <div className="p-6 bg-[#F3F1F1] dark:bg-slate-800 smooth relative text-center flex-center-both w-full max-w-[410px] min-h-[174px]">
                        <div
                            className="right-0 top-0 bottom-0 h-full w-full z-0 absolute opacity-20 dark:opacity-50 smooth"
                            style={{
                                backgroundImage: "url(" + aboutCardPattern + ")",
                                backgroundSize: "contain",
                                backgroundPosition: "center center",
                                backgroundRepeat: "repeat-both",
                            }}
                        ></div>
                        <p className="font-rubik text-xl clr-text-primary smooth  z-10 flex-center-both">
                            بنقدم مميزات كثيرة جدا للطلاب تساعدهم فى المذاكرة والمتابعة وبنعملهم
                            مسابقات اسبوعية وشهرية وجوائز قيمة
                        </p>
                    </div>
                    <div className="p-6 relative bg-[#F3F1F1] dark:bg-slate-800 smooth text-center flex-center-both w-full max-w-[410px] min-h-[174px]">
                        <div
                            className="right-0 top-0 bottom-0 h-full w-full z-0 absolute opacity-20 dark:opacity-50 smooth"
                            style={{
                                backgroundImage: "url(" + aboutCardPattern + ")",
                                backgroundSize: "contain",
                                backgroundPosition: "center center",
                                backgroundRepeat: "repeat-both",
                            }}
                        ></div>
                        <p className="font-rubik clr-text-primary smooth  z-10 text-xl flex-center-both">
                            وامتيازات كثيرة جدا للمدرسين من حيث التقارير الى بنقدمها بشكل دورى
                            للمدرس ووسائل عديدة لمتابعة الطلاب ومستواهم
                        </p>
                    </div>
                    <div className="absolute bg-[#F3F1F1] dark:bg-slate-800 smooth left-8 -z-10 w-[80px] top-0 bottom-0">
                        <div
                            className="right-0 top-0 bottom-0 h-full w-full z-0 absolute opacity-20 dark:opacity-50 smooth"
                            style={{
                                backgroundImage: "url(" + aboutCardPattern + ")",
                                backgroundSize: "contain",
                                backgroundPosition: "center center",
                                backgroundRepeat: "repeat-both",
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;

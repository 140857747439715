import "./teacher.css";
import {
    motion,
    useScroll,
    useMotionValue,
    useSpring,
    useTransform,
    useVelocity,
    useAnimationFrame,
} from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { wrap } from "@motionone/utils";

function ParallaxTeacher({ children, baseVelocity = 100, moveX }) {
    const baseX = useMotionValue(100);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400,
    });

    const [motionDivWidth, setMotionDivWidth] = useState(0);
    const motionDivRef = useRef(null);

    // Calculate the total width of the motion div
    useEffect(() => {
        if (motionDivRef.current) {
            setMotionDivWidth(motionDivRef.current.offsetWidth);
        }
    }, [children]);

    // Convert moveX from pixels to percentage based on motionDivWidth
    const moveXPercentage = motionDivWidth > 0 ? (moveX / motionDivWidth) * 100 : 0;

    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false,
    });

    const x = useTransform(baseX, (v) => {
        const wrappedValue = wrap(-10, 12, v);
        const translatedValue = wrappedValue + moveXPercentage;
        return `${translatedValue}%`;
    });

    const directionFactor = useRef(1);

    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 6000);
        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }
        moveBy += directionFactor.current * moveBy * velocityFactor.get();
        baseX.set(baseX.get() + moveBy);
    });

    return (
        <div className="parallaxTeacher">
            <motion.div className="scroller" style={{ x }} ref={motionDivRef}>
                <span>{children}</span>
                <span>{children}</span>
                <span>{children}</span>
                <span>{children}</span>
            </motion.div>
        </div>
    );
}

export default ParallaxTeacher;
